import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

declare const bootstrap: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isHomePage = false;
  classHeader = '';
  @Input() scrollingInto: string | null;
  showHeader: boolean = true;
  lastScrollPosition: number = 0;
  currentLanguage: { text: string, icon: string } = { text: " EN ", icon: " 🇺🇸 " };
  //currentLanguage: { text: string, icon: string } = { text: " ES ", icon: " 🇲🇽 " };

  navItems: any;

  currentItem1: any;

  currentItem2: any;

  translateSub: any;

  constructor(private router: Router, private route: ActivatedRoute, public translate: TranslateService,) { }

  ngOnInit() {
    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
     setTimeout(() => {
      this.isHomePage = this.router.url === '/';
     },300);
      }
    });

    
 
    this.translateSub = this.translate.stream('header.navItems').subscribe((data: any) => {
      this.navItems = data;
    });
    
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
   if (!this.isHomePage) {
    const currentScrollPosition = window.pageYOffset;
    this.showHeader = currentScrollPosition <= this.lastScrollPosition;
    this.lastScrollPosition = currentScrollPosition;

   } else {
    this.isHomePage = true;
   }
  }

  selectItem(item: any, level: number) {
    if (level === 1) {
      this.currentItem1 = item;
    }
    else {
      if (item.subitems && item.subitems.length > 0) {
        this.currentItem2 = item;
      }
    }
  }

  navBack() {
    if (this.currentItem2) {
      this.currentItem2 = undefined;
    }
    else if (this.currentItem1) {
      this.currentItem1 = undefined;
    }
  }


  ngAfterViewInit() {
    const popoverTriggerList: any = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl));
  }
  scrollToTop(): void {
    window.scrollTo({ top: 0 });
  }

  showMenu() {
    const menu = document.getElementsByClassName('menu');
    if (menu[0].classList.contains('menu-open')) {
      menu[0].classList.remove('menu-open');
      document.getElementsByTagName('body')[0].style.overflow = "scroll";
      setTimeout(() => {
        this.currentItem1=undefined;
        this.currentItem2=undefined;
      }, 300);
    }
    else {
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
      menu[0].classList.add('menu-open');
    }
  }

  useLanguage(language: string): void {
    this.translate.use(language);
    this.currentLanguage = { text: language.toUpperCase(), icon: language === "en" ? " 🇺🇸 " : " 🇲🇽 " };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.scrollingInto && changes.scrollingInto.currentValue != '') {
      const { currentValue: navId } = changes.scrollingInto;
      const navLinks = document.querySelectorAll('.main-navigation a');
      navLinks.forEach(element => {
        element.classList.remove('active');
      });
      const activeLink = document.querySelector(`.main-navigation a[href*='${navId}']`);
      activeLink?.classList.add('active');

    }
  }

  myCartElement() {

    return `
    <div class="popover" role="tooltip">
      <div class="header">
        <h3>{{ 'home.menu7' | translate }}</h3>
      </div>  
      <div class="body">
        <h4>{{ 'home.menu8' | translate }}</h4>
      </div>
    </div>`;
  }

  redirect() {
    this.router.navigate(['login'], { relativeTo: this.route });
  }


  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

}
