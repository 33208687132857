<!--<div class="container-fluid">
  <p class="zoom-area error"><b>{{'error.not' | translate}}</b></p>
  <section class="error-container">
    <span class="four"><span class="screen-reader-text">4</span></span>
    <span class="zero"><span class="screen-reader-text">0</span></span>
    <span class="four"><span class="screen-reader-text">4</span></span>
  </section>
  <div class="link-container footer-action justify-content-center error_bt">
    <input type="button" class="btn btn-outline-dark bot-bck" routerLink="/" value="{{ 'error.button' | translate }}" />
  </div>
</div> -->

<main class='container'>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>0</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <span class='particle'>4</span>
  <article class='content'>
    <p>{{'error.not' | translate}}</p>
    <p>Error <strong>404</strong></p>
    <p>
      <button routerLink="/" >{{ 'error.button' | translate }}</button>
    </p>
  </article>
</main>