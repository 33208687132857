import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ViewportScroller } from '@angular/common';
import { RecaptchaService } from '../../services/recaptcha/recaptcha.service';
import { EmailService } from '../../services/email/email.service';
import { AlertService } from '../../services/alert/alert.service';
import { LoaderService } from '../../services/loader/loader.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  captchaAction: string = 'ALPHA_email_form';
  feedbackForm: FormGroup;
  openAlert: boolean = false;
  contactMessages: any;
  translateSub: any;
  translateSubMessages: any;
  imageEvidence: string;

  constructor(
    private scroller: ViewportScroller,
    private _recaptchaService: RecaptchaService,
    private formBuilder: FormBuilder,
    private _emailService: EmailService,
    private _alertService: AlertService,
    private translate: TranslateService,
    private _loaderService: LoaderService
  ) {}

  ngOnInit() {
    this.translateSub = this.translate
      .stream('messages')
      .subscribe((data: any) => {
        this.contactMessages = data;
      });
    this.feedbackForm = this.formBuilder.group({
      name: ['', Validators.required],
      subject: ['', Validators.required],
      feedback_type: ['', Validators.required],
      evidence: [''],
      section: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const header: number =
        document.getElementById('header')?.offsetHeight || 0;
      this.scroller.setOffset([0, header]);
    }, 1000);
  }

  scrollDown() {
    this.scroller.scrollToAnchor('firstSection');
  }

  initForm(): void {
    this.feedbackForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'),
        ],
      ],
      message: ['', [Validators.required, Validators.minLength(10)]],
    });
  }

  chooseFile(e: any) {
    const file = e.target.files[0];
    const reader = new FileReader();
    const maxSize = 2 * 1024 * 1024; // Tamaño máximo en bytes (2MB)
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

    if (file.size > maxSize) {
      // Mostrar mensaje de error al usuario
      alert('El archivo excede el tamaño máximo permitido de 2MB.');
      e.target.value = '';
      return;
    }
    // Verificar el tipo de archivo
    if (!allowedTypes.includes(file.type)) {
      // Mostrar mensaje de error al usuario
      alert('Solo se permiten archivos de imagen (JPEG, JPG, PNG, GIF).');
      e.target.value = '';
      return;
    }

    reader.readAsDataURL(file);

    reader.onload = (readerEvent: any) => {
      this.imageEvidence = readerEvent.target.result;
    };
  }

  async sendEmail() {
    try {
      const recaptcha: string = await this._recaptchaService.executeAction(
        this.captchaAction
      );

      this._loaderService.subject.next(true);

      const emailData: any = {
        name: this.feedbackForm.get('name')?.value,
        subject: this.feedbackForm.get('subject')?.value,
        feedback_type: this.feedbackForm.get('feedback_type')?.value,
        evidence: this.imageEvidence,
        section: this.feedbackForm.get('section')?.value,
        comments: this.feedbackForm.get('comments')?.value,
        recaptchaToken: recaptcha,
        recaptchaAction: this.captchaAction,
      };

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('Timeout occurred while sending email.'));
        }, 5000); // 5 segundos
      });

      const feedbackSub = this._emailService.feedback(emailData).subscribe({
        next: (data) => {
          feedbackSub.unsubscribe();
          if (data.status === 'success') {
            this.feedbackForm.reset();
            this._alertService.showAlert.next({
              isOpen: true,
              type: 'success',
              title: 'Success',
              message: 'Email sent successfully.',
            });
          } else {
            this._alertService.showAlert.next({
              isOpen: true,
              type: 'error',
              title: 'Error',
              message: 'Failed to send email.',
            });
          }
        },
        error: (error) => {
          feedbackSub.unsubscribe();
          this._alertService.showAlert.next({
            isOpen: true,
            type: 'error',
            title: 'Error',
            message: 'An error occurred while sending email.',
          });
        },
      });

      await Promise.race([
        timeoutPromise,
        new Promise((resolve) => feedbackSub),
      ]);
    } catch (e) {
      this._alertService.showAlert.next({
        isOpen: true,
        type: 'error',
        title: 'Error',
        message: 'An error occurred while sending email.',
      });
    } finally {
      this._loaderService.subject.next(false);
    }
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }
}
