import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, TemplateRef } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RecaptchaService } from '../../services/recaptcha/recaptcha.service';
import { EmailService } from '../../services/email/email.service';
import { AlertService } from '../../services/alert/alert.service';
@Component({
  standalone: true,
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss'],
  imports: [TranslateModule, ReactiveFormsModule, FormsModule]
})
export class InformationComponent {

  modalRef?: BsModalRef;

  infoForm: FormGroup;

  captchaAction: string = "ALPHA_info_form";

  informationMessages: any;

  translateSub: any;

  constructor(private modalService: BsModalService, private fb: FormBuilder, private _recaptchaService: RecaptchaService,
    private _emailService: EmailService, private _alertService: AlertService, private translate: TranslateService) {
    this.initForm();
  }

  ngOnInit() {
    this.translateSub = this.translate.stream('messages').subscribe((data: any) => {
      this.informationMessages = data;
    });
  }

  initForm() {
    this.infoForm = this.fb.group({
      name: ["", [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
        ],
      ],
      message: ["", [Validators.required, Validators.minLength(15)]]
    });
  }

  openModal(template: TemplateRef<void>) {
    this.modalRef = this.modalService.show(template);
  }

  checkInvalidFields(): void {
    if (this.infoForm.invalid) {
      Object.values(this.infoForm.controls).map((control) => {
        control.markAsTouched();
      });
    }
  }

  get isNameValid(): boolean | undefined {
    return (
      this.infoForm.get("name")?.invalid &&
      this.infoForm.get("name")?.touched
    );
  }

  get isPhoneValid(): boolean | undefined {
    return (
      this.infoForm.get("phone")?.invalid &&
      this.infoForm.get("phone")?.touched
    );
  }

  get isEmailValid(): boolean | undefined {
    return (
      this.infoForm.get("email")?.invalid &&
      this.infoForm.get("email")?.touched
    );
  }

  get isMessageValid(): boolean | undefined {
    return (
      this.infoForm.get("message")?.invalid &&
      this.infoForm.get("message")?.touched
    );
  }

  async onSubmit() {

    try {
      if (this.infoForm.invalid) {
        return this.checkInvalidFields();
      }

      const recaptcha: string = await this._recaptchaService.executeAction(
        this.captchaAction
      );

      const emailData: any = {
        name: this.infoForm.get("name")?.value,
        phone: this.infoForm.get("phone")?.value,
        email: this.infoForm.get("email")?.value,
        message: this.infoForm.get("message")?.value,
        process: 'informationModal',
        recaptchaToken: recaptcha,
        recaptchaAction: this.captchaAction,
      };

      const emailContactSub = this._emailService.sendEmail(emailData).subscribe({
        next: (data) => {
          emailContactSub.unsubscribe();
          if (data.status === "success") {
            this.infoForm.reset();
            this.modalRef?.hide();
            setTimeout(() => {
              this._alertService.showAlert.next({ isOpen: true, type: 'success', title: this.informationMessages.modal.send, message: this.informationMessages.modal.success });
            }, 2000);
          } else {
            this._alertService.showAlert.next({ isOpen: true, type: 'error', title: this.informationMessages.error, message: this.informationMessages.modal.error });
          }
        },
        error: (error) => {
          emailContactSub.unsubscribe();
          this._alertService.showAlert.next({ isOpen: true, type: 'error', title: this.informationMessages.error, message: this.informationMessages.modal.ups });
        },
      });
    } catch (e) {
      this._alertService.showAlert.next({ isOpen: true, type: 'error', title: this.informationMessages.error, message: this.informationMessages.modal.ups });
    }
  }

  ngOnDestroy() {
    if (this.translateSub) {
      this.translateSub.unsubscribe();
    }
  }

}
