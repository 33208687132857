<!--INICIO HERO-->
<section class="hero-section">
    <div class="hero-container">
        <div class="found-container">
            <h1> {{ "home.hero" | translate }} <br /> {{ "home.hero1" | translate }} </h1>
        </div>
        <div class="logo-container">
            <img src="assets/images/Alpha_wh_br.png" alt="logo" />
        </div>
        <div class="description-container">
            <h1>{{ "home.hero2" | translate }}</h1>
            <h5>{{ "home.hero3" | translate }}</h5>
        </div>
        <div class="scroll">
            <p>
                <span (click)="scrollDown()">
                    <i class="fa-solid fa-circle-arrow-down"></i>
                    {{ 'buttons.scroll' | translate }}
                </span>
            </p>
        </div>
    </div>
</section>
<!--FIN HERO-->


<section class=" container services-container">
    <div class="title-section general-title-separation">
        <h1 class="text-center mb-3">{{ "company.serv" | translate }}</h1>
        <div class="underline"></div>
    </div>
    <div class="services-grid">
        <div class="service-item">
            <img src="assets/images/hospitality.jpg" alt="" />
            <h3>{{ "company.serv1" | translate }}</h3>
            <p>{{ "company.serv2" | translate }}</p>
            <input type="button" class="btn btn-outline-dark" routerLink="/hospitality"  value="{{ 'buttons.read' | translate }}" />
        </div>
        <div class="service-item">
            <img src="assets/images/bienestar.jpg" alt="" />
            <h3>{{ "company.serv3" | translate }}</h3>
            <p>{{ "company.serv4" | translate }}</p>
            <input type="button" class="btn btn-outline-dark" routerLink="/destination/serv/spa" value="{{ 'buttons.read' | translate }}" />
        </div>
        <div class="service-item">
            <img src="assets/images/romance1.jpg" alt="" />
            <h3>{{ "company.serv5" | translate }}</h3>
            <p>{{ "company.serv6" | translate }}</p>
            <input type="button" class="btn btn-outline-dark" routerLink="/destination/serv/romance" value="{{ 'buttons.read' | translate }}" />
        </div>
    </div>
</section>

<!--INICIO SECCION VISIT-->
<section class="visit-container">
    <div class="text-center">
        <h1>{{ 'home.visit' | translate }}</h1>
        <p>{{ 'home.visit1' | translate }} </p>
        <input type="button" class="btn btn-outline-dark bot" routerLink="/destination" value="{{ 'buttons.read' | translate }}" />
    </div>
</section>
<!--FIN SECCION VISIT-->


<section class="">
    <div class="tours-container container pt-0">
        <div class="title-section general-title-separation mt-0 pt-100">
            <h1 class="text-center mb-3">{{ 'company.ft' | translate }}</h1>
            <div class="underline"></div>
        </div>
        <div class="swiper">
            <div class="tours-grid swiper-wrapper mt-0">
                <div class="tour-item swiper-slide">
                    <img src="assets/images/hiking.jpg" alt="" />
                    <h3>{{ 'company.ft1' | translate }}</h3>
                    <p>{{ 'company.ft2' | translate }}</p>
                    
                </div>
                <div class="tour-item swiper-slide">
                    <img src="assets/images/snorkel.jpg" alt="" />
                    <h3>{{ 'company.ft3' | translate }}</h3>
                    <p>{{ 'company.ft4' | translate }}</p>
                    
                </div>
                <div class="tour-item swiper-slide">
                    <img src="assets/images/isla.jpg" alt="" />
                    <h3>{{ 'company.ft5' | translate }}</h3>
                    <p>L{{ 'company.ft6' | translate }}</p>
                    
                </div>
                <div class="tour-item swiper-slide">
                    <img src="assets/images/jeep.jpg" alt="" />
                    <h3>{{ 'company.ft7' | translate }}</h3>
                    <p>{{ 'company.ft8' | translate }}</p>
                    
                </div>
            </div>

        </div>

        <div class="controls">
            <div class="left"><i class="fa-solid fa-arrow-left fs-4" (click)="slideRight('tours')"></i></div>
            <div class="right"><i class="fa-solid fa-arrow-right fs-4" (click)="slideLeft('tours')"></i></div>
        </div>
    </div>
</section>


<!--INICIO SECCION LUGARES-->
<section id="places" class="bg-gray">
    <div class="container values-container pt-0 ">
        <div class="title-section pt-100">
            <h1 class="text-center mb-6">{{ 'home.places.place_title' | translate }}</h1>
            <div class="underline"></div>

        </div>
        <p class="subtitle">{{ 'home.places.place_desc' | translate }}</p>
        <div class="grid">
            <div class="item location" routerLink="/destination" fragment="beaches">
                <div class="icon ">
                    <i class="fa-solid fa-location-arrow"></i>
                </div>
                <h1>{{ 'home.places.place1' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="todo">
                <div class="icon">
                    <i class="fa-solid fa-anchor"></i>
                </div>
                <h1>{{ 'home.places.place2' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="places">
                <div class="icon">
                    <i class="fa-solid fa-road"></i>
                </div>
                <h1>{{ 'home.places.place3' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="conectivity">
                <div class="icon">
                    <i class="fa-solid fa-map"></i>
                </div>
                <h1>{{ 'home.places.place4' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="airports">
                <div class="icon">
                    <i class="fa-solid fa-map-pin"></i>
                </div>
                <h1>{{ 'home.places.place5' | translate }}</h1>
            </div>
            <div class="item location" routerLink="/destination" fragment="activities">
                <div class="icon">
                    <i class="fa-solid fa-map-marker"></i>
                </div>
                <h1>{{ 'home.places.place6' | translate }}</h1>
            </div>
        </div>
    </div>
</section>
<!--FIN SECCION LUGARES-->


<section class="">
    <div class="experiences-container container">
        <div class="title-section general-title-separation">
            <h1 class="text-center mb-3">{{ 'company.fe' | translate }}</h1>
            <div class="underline"></div>
        </div>
        <div class="swiper">
            <div class="experiences-grid swiper-wrapper mt-0">
                <div class="experiences-item swiper-slide">
                    <img src="assets/images/dolphins.JPG" alt="" />
                    <h3>{{ 'company.fe1' | translate }}</h3>
                    <p>{{ 'company.fe2' | translate }}</p>
                    
                </div>
                <div class="experiences-item swiper-slide">
                    <img src="assets/images/marlin.jpg" alt="" />
                    <h3>{{ 'company.fe3' | translate }}</h3>
                    <p>{{ 'company.fe4' | translate }}</p>
                    
                </div>
                <div class="experiences-item swiper-slide">
                    <img src="assets/images/whale.jpg" alt="" />
                    <h3>{{ 'company.fe5' | translate }}</h3>
                    <p>{{ 'company.fe6' | translate }}</p>
                    
                </div>
                <div class="experiences-item swiper-slide">
                    <img src="assets/images/sea.jpg" alt="" />
                    <h3>{{ 'company.fe7' | translate }}</h3>
                    <p>{{ 'company.fe8' | translate }}</p>
                    
                </div>
            </div>
        </div>
        <div class="controls-exp">
            <div class="left-exp"><i class="fa-solid fa-arrow-left fs-4" (click)="slideRight('experiences')"></i></div>
            <div class="right-exp"><i class="fa-solid fa-arrow-right fs-4" (click)="slideLeft('experiences')"></i></div>
        </div>
    </div>
</section>

<!--INICIO SECCION NEWS-->
<section class="bg-gray">
    <div class="news-container container pt-0">

        <div class="title-section general-title-separation mt-0 pt-100">
            <h1 class="text-center mb-3">{{ 'news.title' | translate }}</h1>
            <div class="underline"></div>
        </div>

        <p class="sec-description">{{ 'news.subtitle' | translate }}</p>

        <div class="news-grid">
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n2' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n3' | translate }}</h5>
                        <p>{{ 'news.n4' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n5' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n7' | translate }}</h5>
                        <p>{{ 'news.n8' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="news-item">
                <div class="news-body">
                    <p>{{ 'news.n9' | translate }}</p>
                </div>
                <div class="author">
                    <img src="assets/images/avatar.jpg" alt=""/>
                    <div class="right">
                        <h5>{{ 'news.n11' | translate }}</h5>
                        <p>{{ 'news.n12' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-action">
            <input type="button" class="btn btn-outline-dark bot-bck" routerLink="/blog"
                value="{{ 'buttons.see' | translate }}" />
        </div>
    </div>
</section>
<!--FIN SECCION NEWS-->

<!--INICIO SECCION CONTACTO-->
<section class="contact-container mt-0" id="contact">
    <div class="title-section general-title-separation">
        <h1 class="text-center mb-3">{{ 'contact.title' | translate }}</h1>
        <div class="underline"></div>
    </div>
    <div class="border-container">
        <div class="left">
            <h1>{{ 'contact.subtitle' | translate }}</h1>
            <div class="location">
                <i class="fa-solid fa-location-dot fs-4"></i>
                <p class="contact-info">{{ "contact.address" | translate }}</p>
            </div>
            <div class="phone">
                <i class="fa-solid fa-phone fs-4"></i>
                <p class="contact-info"><a href="tel:+526241307060">{{ "contact.phone" | translate }}</a></p>
            </div>
            <div class="address">
                <i class="fa-solid fa-mobile fs-4"></i>
                <p class="contact-info"><a class=""
                        href="https://wa.me/+526241328294?text=Hi!%20I%20need%20info%20about%20Alpha%20Los%20Cabos."
                        target="_blank">{{ "contact.whats" | translate }}</a></p>
            </div>
            <div class="address">
                <i class="fa-solid fa-envelope fs-4"></i>
                <p class="contact-info"><a href="mailto:info@alphatransfers.com.mx?Subject=Info%20about%20ALPHA">{{
                        "contact.mail" | translate }}</a></p>
            </div>
            <!--FORMULARIO-->
            <div class="grid">
                <form [formGroup]="contactForm" (ngSubmit)="sendEmail()" id="contact-form" action="#" method="POST">
                    <div class="row">
                        <div class="col-6">
                            <input type="text" class="form-control" formControlName="name" id="name" autocomplete="off"
                                [class.is-inpt-invalid]="isNameValid" placeholder="{{ 'contact.name' | translate }}">
                        </div>
                        <div class="col-6">
                            <input type="email" class="form-control" formControlName="email" id="email" autocomplete="off"
                                [class.is-inpt-invalid]="isEmailValid" placeholder="{{ 'contact.email' | translate }}">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <textarea class="form-control" formControlName="message" id="message" autocomplete="off"
                                [class.is-inpt-invalid]="isMessageValid" placeholder="{{ 'contact.text' | translate }}"
                                rows="3"></textarea>
                        </div>
                    </div>
                    <div class="pb-4 captcha text-center">
                        {{ "captcha.one" | translate}}
                        <a href="https://policies.google.com/privacy">{{ "captcha.two" | translate}}</a> {{
                        "captcha.three" | translate}}
                        <a href="https://policies.google.com/terms">{{ "captcha.four" | translate}}</a> {{
                        "captcha.five" | translate}}
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <input type="submit" class="btn btn-outline-dark btn-submit"
                                value="{{ 'buttons.send' | translate }}" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!--MAPA-->
        <div class="right">
            <div class="map" id="map"></div>
        </div>
    </div>
</section>

<!--FIN SECCION CONTACTO-->

<!--MODAL-->

<app-alert></app-alert>

<!--BANNER COOKIES-->

<app-cookies></app-cookies>
